import {v4 as uuidv4} from 'uuid'
import {DateTime} from 'luxon'
import flatpickr from 'flatpickr'

const callback = function () {
  validateForms()
  activateListItem()
  setAjax()
  endUserSignupForm()
  setDefaultSlug()
  scheduleTime()
  generateImageUploadForms()
  imageSubmitHandler()
  copyText()
  fadeAlerts()
  paginateTables()
  showOrHideCountryCodeDropdown()
  setTimezoneInput()
}

function scheduleTime() {
  if (typeof flatpickr === 'undefined') {
    console.error('Flatpickr is not loaded. Please include it before this script runs.')
    return
  }

  const scheduleTimeEl = document.getElementById('scheduleTime')
  if (scheduleTimeEl) {
    const rawValue = scheduleTimeEl.dataset.value
    const rangeStart = document.querySelector('[data-range-start]')?.dataset.rangeStart
    const rangeEnd = document.querySelector('[data-range-end]')?.dataset.rangeEnd
    let defaultTime

    if (rawValue) {
      // Parse the time string more strictly
      const parsed = DateTime.fromFormat(rawValue.trim(), 'h:mm a')
      if (parsed.isValid) {
        const parsedTime = parsed.toJSDate()
        if (rangeStart && parsedTime < DateTime.fromFormat(rangeStart, 'H:mm').toJSDate()) {
          defaultTime = DateTime.fromFormat(rangeStart, 'H:mm').toJSDate()
        }
        else if (rangeEnd && parsedTime > DateTime.fromFormat(rangeEnd, 'H:mm').toJSDate()) {
          defaultTime = DateTime.fromFormat(rangeEnd, 'H:mm').toJSDate()
        }
        else {
          defaultTime = parsedTime
        }
      }
      else {
        defaultTime = new Date()
      }
    }
    else {
      // Round current time to nearest 15 minutes
      const now = new Date()
      const minutes = Math.ceil(now.getMinutes() / 15) * 15
      now.setMinutes(minutes)
      now.setSeconds(0)
      defaultTime = now
    }

    flatpickr(scheduleTimeEl, {
      enableTime: true,
      noCalendar: true,
      defaultDate: defaultTime,
      dateFormat: 'h:i K',
      time_24hr: false,
      minuteIncrement: 15,
      enableSeconds: false,
      allowInput: false,
      minTime: rangeStart || '00:00',
      maxTime: rangeEnd || '23:59',
      onChange: function(selectedDates, _dateStr, instance) {
        // Round to next 15 minutes if not already on 15-minute mark
        const date = selectedDates[0]
        const minutes = date.getMinutes()
        const remainder = minutes % 15

        if (remainder !== 0) {
          const roundedMinutes = minutes + (15 - remainder)
          date.setMinutes(roundedMinutes)
          instance.setDate(date)
        }
      },
    })
  }
}

function setTimezoneInput() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  $('#hiddenTimezoneInput').val(timezone)
}

function showOrHideCountryCodeDropdown() {
  const addressTypeSelect = $('.address_type_select')
  const countryCodeDropdownDiv = $('.country_code_dropdown')

  function toggleCountryCodeDiv(addressSelect, countryCodeDiv) {
    const addressType = $(addressSelect).val()
    if (['sms_whatsapp', 'sms'].includes(addressType)) {
      countryCodeDiv.show()
    }
    else {
      countryCodeDiv.hide()
    }
  }

  if (addressTypeSelect) {
    toggleCountryCodeDiv(addressTypeSelect, countryCodeDropdownDiv)

    addressTypeSelect.on('change', function() {
      toggleCountryCodeDiv($(this), countryCodeDropdownDiv)
    })
  }
}

function paginateTables() {
  $('table').each(function () {
    let items = 'items'
    if ($(this).data('item-name')) {
      items = $(this).data('item-name')
    }

    let restrictedLengthMenu = [[10, 25, 50, 500, -1],[10, 25, 50, 500, 'All']]

    let optionsHTML = ''

    for (let i = 0; i < restrictedLengthMenu[0].length; i++) {
      optionsHTML += `<option value="${restrictedLengthMenu[0][i]}">${restrictedLengthMenu[1][i]}</option>`
    }

    let dataTableOptions = {
      info: false,
      lengthChange: true,
      lengthMenu: restrictedLengthMenu,
      ordering: false,
      pagingType: 'simple_numbers',
      dom: '<"mt-2 mx-4"l> t<"pagination"p>',
      language: {
        emptyTable: `There are no ${items} to display!`,
        zeroRecords: `There are no ${items} to display!`,
        paginate: {
          next: 'Next >',
          previous: '< Prev',
        },
        lengthMenu:
          '<div class="container-sm flex items-center">' +
          '<span class="text-sm font-medium leading-6 text-gray-900 mr-1">Show</span>' +
          '<select id="location" name="location" class="shadow-sm mx-2 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-orange-500 sm:text-sm sm:leading-6">' +
          optionsHTML +
          '</select>' +
          '<span class="text-sm font-medium leading-6 text-gray-900 ml-1">Entries</span>' +
          '</div>',
      },
    }

    // create and add search element before table NOTE: searchInput needs to be its own element in order for the event listeners to work and needs to be appended to fullSearch instead of injecting the html directly so ensure the correct element has the listener
    const searchInputWidth = 'w-1/3'
    const searchInput = $('<input type="search" class="w-full rounded-md border-0 py-1.5 pl-9 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" placeholder="Search…">')

    const fullSearch = $('<div class="relative rounded-md shadow-sm w-full">')
      .append($('<div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2.5">')
        .append($('<span class="text-gray-500 sm:text-sm">')))
      .append(searchInput)

    const controlsWrapper = $(`<div class="flex align-start float-right mt-2 mx-4 ${searchInputWidth}">`)
    controlsWrapper.append(fullSearch)

    $(this).before(controlsWrapper)
    if ($(this).data('ajax')) {
      dataTableOptions['serverSide'] = true
      dataTableOptions['processing'] = true
    }

    const table = $(this).DataTable(dataTableOptions)

    // add search functionality to table
    searchInput.on('keyup change clear search', function () {
      table.search(this.value).draw()
    })
  })
}

function next() {
  $('#step').val(window.step + 1)
  $('#registration-form').trigger('submit')
}

function prev() {
  $('#step').val(window.step - 1)
  $('#registration-form').trigger('submit')
}

//Check to see if everything is loaded before running or else our crap isn't going to be there
if (document.readyState === 'complete' || (document.readyState !== 'loading' && !document.documentElement.doScroll)) {
  callback()
}
else {
  document.addEventListener('DOMContentLoaded', callback)
}

function setAjax() {
  const pageToken = $('meta[name="csrf-token"]').attr('content')
  const storedToken = localStorage.getItem('csrfToken')

  $.ajaxPrefilter(function(_options, _originalOptions, xhr) {
    if (storedToken !== pageToken) {
      xhr.setRequestHeader('X-CSRF-Token', storedToken)
    }
  })

  $.ajaxSetup({
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': localStorage.getItem('csrfToken'),
    },
  })
}

function setDefaultSlug() {
  $('input[name=\'organization[name]\']').on('change', function () {
    let name = $('input[name=\'organization[name]\']').val()
    $.post('/organizations/generate_default_slug', {organization: {name: name}}).done(function (data) {
      let slug = data['slug']
      $('input[name=\'organization[slug]\']').val(slug)
    })
  })
}

//Clientside validation for all forms
function validateForms() {
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  const forms = document.getElementsByClassName('needs-validation')
  // Loop over them and prevent submission
  Array.prototype.filter.call(forms, function (form) {
    form.addEventListener('submit', function (event) {
      if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
      }
      form.classList.add('was-validated')
    }, false)
  })
}

function activateListItem() {
  $('input[type=radio]').on('click', function () {
    //Get the parent node of the input
    const listItem = this.parentNode
    //Remove all active classes
    $('.list-group-item').removeClass('active')

    //If checkbox is checked add active class
    if ($(this).is(':checked')) {
      $(listItem).addClass('active')
    }
  })
}

function endUserSignupForm() {
  //If signup form exists
  if (document.querySelector('#course-registration')) {
    $('#manual-address').on('change', function() {
      $('#address-select').val('')
    })
    $('#manual-address').on('keyup', function() {
      $('#address-select').val('')
    })
    $('#address-select').on('change', function() {
      $('#manual-address').val(null)
    })
    const contButton = document.querySelector('#contButton')
    const backButton = document.querySelector('#backButton')
    if (contButton) {
      contButton.addEventListener('click', next)
    }
    if (backButton) {
      backButton.addEventListener('click', prev)
    }
    const scheduleTime = $('#scheduleTime')
    if (scheduleTime) {
      let val = scheduleTime.data('value')
      if (val && val.length > 0) {
        scheduleTime.val(val)
      }
    }
  }
}

function imageSubmitHandler() {
  $('.image-upload').children('form').on('submit', function(e) {
    e.preventDefault()
  })
}
function generateImageUploadForms() {
  $('.image-upload').each( function() {
    const formDiv = $(this)
    const previewImgContainer = $(`#${formDiv.data('preview-image-container-id')}`)
    var destKey = uuidv4()

    if ((document.location.hostname == 'localhost' || document.location.hostname == '127.0.0.1') && document.location.port >= 8889) {
      destKey = '2e9d07ab-b49e-4dde-9c3f-384e0d910812' // Test Key...
    }

    const desiredWidth = formDiv.data('desired-width') || null
    const desiredHeight = formDiv.data('desired-height') || null
    const postData = {desired_width: desiredWidth, desired_height: desiredHeight, dest_key: destKey}
    $.post('/generate_image_upload_form', postData).done(function(data) {
      formDiv.html(data)
      const formId = formDiv.data('form-id')
      const fieldName = formDiv.data('field-name')
      const imageKey = formDiv.data('image-key')
      const targetForm = $(`#${formId}`)
      let inputElement = targetForm.find(`input[name="${fieldName}"]`)[0]
      if (!inputElement) {
        inputElement = document.createElement('input')
        inputElement.type = 'hidden'
        inputElement.name = fieldName
        inputElement.value = imageKey
        targetForm.prepend(inputElement)
      }
      getImgPreview(formDiv, destKey, inputElement, previewImgContainer)
    })
  })
}

//Gets an image preview, creates blob to display local image
function getImgPreview(formDiv, destKey, inputElement, previewImgContainer) {
  const courseFile = document.getElementById('courseFile')
  const previewImg = previewImgContainer.find('.preview-img')
  const progressBarDiv = previewImgContainer.find('.progress')
  const progressBar = previewImgContainer.find('.progress-bar')
  const span = formDiv.find('.file-name-span')
  const fileName = previewImgContainer.data('fileName')
  const deleteImagePreviewButton = previewImgContainer.find('#deleteImage')
  let progressBarValue = 0

  //If file input exists
  if (courseFile) {
    if (fileName) {
      span.text(fileName)
    }
    courseFile.addEventListener('change', () => {
      //Use URL api to create blob url to display image preview
      if (courseFile.files && courseFile.files[0]) {
        const inputs = $('.footer button, input[type=\'file\']')
        const form = formDiv.children('form')[0]
        const imgKey = $(form).find('input[name="key"]')
        const action = form.action
        const file = courseFile.files[0]
        const newFileName = file.name.replace(/[^a-z0-9.]/gi,'a')
        const courseFileName = shortenFileName(courseFile.files[0].name)
        span.text(courseFileName)
        let progressBarInterval

        const tempBucketFileName = imgKey.val().replace('${filename}', newFileName)
        const xhr = new XMLHttpRequest()
        const formData = new FormData( form )
        formData.delete('file')
        formData.append('file', file, newFileName)
        previewImg.attr('src', '')
        // Define what happens on successful data submission
        xhr.addEventListener( 'load', function() {
          if ( xhr.status !== 201) {
            progressBarDiv.hide()
            $('.preview-img-container .status-message').text(null)
            progressBarValue = 0
            progressBar.width(`${progressBarValue}%`)
            progressBar.attr('aria-valuenow', progressBarValue)
            clearInterval(progressBarInterval)
            deleteImagePreviewButton.show()
            inputs.each(function() {
              $(this).prop('disabled', false)
            })
            span.text('')
            return
          }

          let fullImageUrl
          $.post('/generate_presigned_url_for_image_upload', {file_name: tempBucketFileName, dest_key: destKey}).done(function (data) {
            fullImageUrl = data['presigned_url']
          }).fail((error) => {
            console.error(error)
            progressBarDiv.hide()
            progressBarValue = 0
            progressBar.width(`${progressBarValue}%`)
            progressBar.attr('aria-valuenow', progressBarValue)
            clearInterval(progressBarInterval)
            deleteImagePreviewButton.show()
            inputs.each(function() {
              $(this).prop('disabled', false)
            })
            span.text('')
            return
          })

          let tries = 0
          const interval = setInterval(tryImageUrl, 1000)

          function tryImageUrl() {
            if (++tries >= 60) {
              progressBarDiv.hide()
              if (tries < 1000) {
                $('.preview-img-container .status-message').text('Timed out...')
              }
              progressBarValue = 0
              progressBar.width(`${progressBarValue}%`)
              progressBar.attr('aria-valuenow', progressBarValue)
              clearInterval(progressBarInterval)
              deleteImagePreviewButton.show()
              inputs.each(function() {
                $(this).prop('disabled', false)
              })
              span.text('')
              clearInterval(interval)
              return
            }
            $.get(fullImageUrl).done(function (_data, status) {
              if (status === 'success') {
                previewImg.attr('src', fullImageUrl)
                inputElement.value = destKey
                progressBarDiv.hide()
                $('.preview-img-container .status-message').text(null)
                progressBarValue = 0
                progressBar.width(`${progressBarValue}%`)
                progressBar.attr('aria-valuenow', progressBarValue)
                clearInterval(progressBarInterval)
                deleteImagePreviewButton.show()
                inputs.each(function() {
                  $(this).prop('disabled', false)
                })
                previewImgContainer.data('fileName', courseFileName)
                clearInterval(interval)
                generateImageUploadForms()
                return
              }
            })
            $.get(`/image_status/${destKey}`).done(function(data) {
              if (data.status == 'finished') {
                $('.preview-img-container .status-message').text(null)
              }
              else {
                $('.preview-img-container .status-message').text(data.message)
                if (data.status == 'error') {
                  tries = 1000 // Stop it
                }
              }
            })
          }
        })
        // Define what happens in case of error
        xhr.addEventListener( 'error', function() {
          // Q: what do we want to do here?
          alert( 'Oops! Something went wrong.' )
        } )
        xhr.addEventListener('load', () => {
          $.post('/process_image', {key: tempBucketFileName}).done( (data) => {
            if (data.status === 'processing') {
              $('.preview-img-container .status-message').text('Starting processing...')
            }
            else {
              $('.preview-img-container .status-message').text(data.message)
            }
          })
        })
        // Set up our request
        xhr.open( 'POST', action )
        // The data sent is what the user provided in the form
        xhr.send( formData )
        // show progressBar
        progressBarDiv.show()
        $('.preview-img-container .status-message').text('Uploading image...')
        progressBarValue = 0
        progressBar.width(`${progressBarValue}%`)
        progressBar.attr('aria-valuenow', progressBarValue)
        clearInterval(progressBarInterval)
        deleteImagePreviewButton.hide()
        // disable inputs
        inputs.each(function() {
          $(this).prop('disabled', true)
        })

        let secondCount = 0
        progressBarInterval = setInterval(() => {
          progressBarValue += 2
          progressBar.width(`${progressBarValue}%`)
          progressBar.attr('aria-valuenow', progressBarValue)
          secondCount++
          if (secondCount >= 50) {
            clearInterval(progressBarInterval)
          }
        }, 1000)
      }
    })
  }
}

function shortenFileName(fileName) {
  if (fileName.length > 24) {
    const firstPart = fileName.slice(0, 10)
    const secondPart = fileName.slice(fileName.length - 12, fileName.length)
    return `${firstPart}..${secondPart}`
  }
  else {
    return fileName
  }
}

function copyText() {
  $('#copyTextBtn').on('click', () => {
    $('.alert-success').remove()
    const copyText = $('#copyText').val()
    navigator.clipboard.writeText(copyText)
    const mainContent = $('#main-content')
    mainContent.prepend('<div class=\'alert alert-success\' role=\'alert\'>Copied!</div>')
    fadeAlerts()
  })
}

function fadeAlerts() {
  setTimeout(function() {
    $('.alert').fadeOut('fast')
  }, 5000)
}
